import { capture, init } from "./posthog";

export * from "./index";

type BootstrapProps = {
	key?: string;
	apiHost?: string;
	trackLocal?: boolean;
}

export const bootstrap = async (configs: BootstrapProps) => {
	const key =
		configs.key ||
		window.process.env.POSTHOG_KEY ||
		"phc_DBXgI3IEUKWEl075vvkGMSBlrT7Qtv4VQxMOXzj3gFz";
	const apiHost =
		configs.apiHost ||
		window.process.env.POSTHOG_API_HOST ||
		"https://eu.i.posthog.com";

	const trackLocal = !!configs.trackLocal || !!window.process.env.TRACK_LOCAL;

	console.log("[app-track] initializing...");
	init({ key, apiHost, trackLocal });

	window.addEventListener("error", (event) => {
		console.log("An uncaught error happened", event);
		capture("unhandled-error", {
			type: event.type,
			message: event.message,
			source: `${event.filename}:${event.lineno}`,
		});
		event.preventDefault();
	});

	window.addEventListener("unhandledrejection", (event) => {
		// preventing the rejection from appearing in the console
		capture("unhandled-rejection", {
			type: event.type,
			message: event.reason,
		});
		event.preventDefault();
	});

	console.log("[app-track] done.");
};

export const mount = (element: HTMLElement) => {
	console.log("[app-theme] Mounting");
};
