import posthog from "posthog-js";

type InitProps = {
	key: string;
	apiHost: string;
	trackLocal: boolean;
};

export const init = ({ key, apiHost, trackLocal }: InitProps) => {
	if (
		trackLocal ||
		(!window.location.host.includes("127.0.0.1") &&
			!window.location.host.includes("localhost"))
	) {
		posthog.init(key, { api_host: apiHost });
	}
};

export const capture = (
	eventName: string,
	props: Record<string, string | boolean | number>,
) => {
	posthog.capture(eventName, props, {});
};
